
 <template>
  <div>
    <!-- modal login-->
    <b-modal
      id="add-product"
      cancel-variant="outline-secondary"
      centered
      hide-footer="true"
      no-close-on-backdrop
      title="Add Service"
    >
      <b-form>
        <b-row>
          <b-col> </b-col>
          <b-col>
            <div v-if="moreloading == true">
              <b-spinner
                variant="primary"
                type="grow"
                class="m-5"
                label="Spinning"
              ></b-spinner>
            </div>

            <b-link class="p-1">
              <b-img
                ref="previewEl"
                rounded
                thumbnail
                src="https://dk0pm9zdlq16s.cloudfront.net/add9143e-45af-4c67-a83a-842aa52b1121.png"
                @click="$refs.mfile.click()"
                v-if="moreloading == false"
                height="120"
                width="120"
              />

              <!-- <img v-if= "loading==false" rounded :src="require('@/assets/images/elements/Unicorn-Cake.jpg')" @click="$refs.files.click()" style="width: 7vw; height: 7vw; padding-left:20px; padding-top:20px;" alt="imgAdd"/>  -->
            </b-link>

            <input
              type="file"
              id="mfile"
              hidden
              ref="mfile"
              multiple="multiple"
              @change="selectMoreFile()"
              accept="image/*"
            />
          </b-col>
          <b-col> </b-col>
        </b-row>

        <b-row>
          <b-col
            class="mt-1"
            style="margin-left: 2px"
            cols="*"
            v-for="(img, index) in myObj.images"
            :key="img.id"
          >
            <b-link>
              <div>
                <i
                  class="fa fa-trash fa-sm position-absolute m-5"
                  @click="removeImg(index)"
                ></i>
              </div>

              <div>
                <b-img rounded :src="img" height="120" width="120" />
              </div>
              <!-- <img v-if= "loading==false" rounded :src="require('@/assets/images/elements/Unicorn-Cake.jpg')" @click="$refs.files.click()" style="width: 7vw; height: 7vw; padding-left:20px; padding-top:20px;" alt="imgAdd"/>  -->
            </b-link>
          </b-col>
        </b-row>
        <div class="text-center" v-if="this.imgerr">
          <small class="text-danger ml-1">Image is required.</small>
        </div>
        <!-- <b-row>
          <b-col> </b-col>
          <b-col>
            <div v-if="moreloading == true">
              <b-spinner
                variant="primary"
                type="grow"
                class="m-5"
                label="Spinning"
              ></b-spinner>
            </div>

            <b-link class="p-1">
              <b-img
                ref="previewEl"
                rounded
                thumbnail
                src="https://dk0pm9zdlq16s.cloudfront.net/add9143e-45af-4c67-a83a-842aa52b1121.png"
                @click="$refs.mfile.click()"
                v-if="moreloading == false"
                height="120"
                width="120"
              />

            </b-link>

            <input
              type="file"
              id="mfile"
              hidden
              ref="mfile"
              multiple="multiple"
              @change="selectMoreFile()"
              accept="image/*"
            />
          </b-col>
          <b-col> </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-link v-for="(img, index) in myObj.images" :key="img.id">
              <div>
                <i
                  class="fa fa-trash fa-sm position-absolute m-5"
                  @click="removeImg(index)"
                ></i>
              </div>

              <div>
                <b-img rounded :src="img" height="120" width="120" />
              </div>
            </b-link>
          </b-col>
        </b-row> -->

        <div class="mt-1">
          <b-form-group
            label="Name"
            invalid-feedback="Service name is required."
          >
            <b-form-input
              id="name-input"
              ref="name"
              v-model="myObj.name"
              @focusout="CheckName()"
              placeholder="Enter service name here."
              required
            />
          </b-form-group>

          <b-form-group
            label="Category"
            invalid-feedback="Category is required."
            ref="category"
          >
            <v-select
              v-model="myObj.categoryId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :reduce="(val) => val.id"
              :options="categories"
              label="name"
              :clearable="false"
              input-id="id"
              ref="category"
              @search:blur="CheckCategory()"
              @input="LoadsubCategory()"
              :disabled="dataloading"
              placeholder="Select category"
            />
          </b-form-group>

          <b-form-group
            label="Sub category"
            invalid-feedback="Sub category is required."
            ref="subcategory"
          >
            <v-select
              v-model="myObj.subcategoryId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :reduce="(val) => val.id"
              :options="mysubCategories"
              label="name"
              :clearable="false"
              ref="subcategory"
              input-id="id"
              @search:blur="CheckSubCategory()"
              :disabled="dataloading"
              placeholder="Select sub category"
            />
          </b-form-group>

          <b-form-group
            label="Charges"
            invalid-feedback="Please enter service charges."
          >
            <b-form-input
              type="number"
              ref="charges"
              placeholder="Enter service charges here."
              required
              @focusout="CheckCharges"
              v-model="myObj.charges"
            />
          </b-form-group>

          <b-form-group
            label="Availability"
            invalid-feedback="Availability is required."
            ref="availability"
          >
            <v-select
              v-model="myObj.forHome"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="availability"
              :clearable="false"
              input-id="id"
              ref="availability"
              @search:blur="CheckAvailability()"
              @input="HomeCharges"
              :disabled="dataloading"
              placeholder="Select service availability."
            />
          </b-form-group>

          <b-form-group
            v-if="this.showVisit"
            label="Visiting charges"
            invalid-feedback="Please enter product price."
          >
            <b-form-input
              type="number"
              ref="bname"
              placeholder="Enter visiting charges here."
              required
              v-model="myObj.chargesAtHome"
            />
          </b-form-group>

          <b-form-group
            label="Description"
            invalid-feedback="Description is required."
            ref="desc"
          >
            <b-form-textarea
              id="textarea-default"
              placeholder="Enter description here."
              v-model="myObj.description"
              ref="desc"
              @focusout="CheckDescription"
              rows="3"
            />
          </b-form-group>
        </div>
      </b-form>
      <hr />
      <div style="">
        <b-button
          class="float-right ml-5"
          variant="primary"
          @click="AddProduct()"
        >
          <span class="text-nowrap">Save</span>
        </b-button>
      </div>
    </b-modal>
    <b-modal
      id="modal-login"
      cancel-variant="outline-secondary"
      ok-title="Show data"
      centered
      @ok="Filter()"
      title="Filters"
    >
      <b-form>
        <!-- <b-form-group>
          <h5>Select date range</h5>
       <div>
      <flat-pickr
      v-model="date.range"
      class="form-control"
      :config="{ allowInput: true, mode: 'range' }"

      ></flat-pickr>
     </div>
       </b-form-group> -->
        <br />
        <b-form-group>
          <h5>Select filter</h5>
          <v-select
            v-model="filterData"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="productfilterOptions"
            label="text"
            :clearable="false"
            placeholder="Select Filter"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <div no-body class="mb-2" style="padding: 20px" cols="12" md="4" sm="12">
      <div class="">
        <!-- Table Top -->
        <b-row align-h="center">
          <!-- Search -->

          <b-col cols="*">
            <b-button @click="EmptyForm()" variant="primary">
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="text-nowrap">Add Service</span>
            </b-button>
          </b-col>
          <b-col cols="*" class="ml-1">
            <!-- <b-button v-b-modal.modal-login variant="outline-primary">
              <feather-icon icon="FilterIcon" class="mr-50" />
              <span class="text-nowrap">Filters</span>
            </b-button> -->
          </b-col>

          <b-col xl="7" lg="7" md="5" class="mt-lg-0 mt-md-0 mt-sm-1 mt-1">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block"
              placeholder="Search..."
            />
          </b-col>

          <b-col cols="1" class="mt-lg-0 mt-md-0 mt-sm-1 mt-1">
            <b-form-radio-group
              v-model="itemView"
              class="list item-view-radio-group"
              buttons
              size="sm"
              button-variant="outline-primary"
            >
              <b-form-radio
                v-for="option in itemViewOptions"
                :key="option.value"
                :value="option.value"
              >
                <feather-icon :icon="option.icon" size="20" />
              </b-form-radio>
            </b-form-radio-group>
          </b-col>

          <!-- Per Page -->
        </b-row>
      </div>

      <div class="mt-2" v-if="itemView == 'list-view'">
        <b-card>
          <b-table :items="filterProducts" :fields="columns" responsive striped>
            <template #cell(cover)="data">
              <b-img
                :src="data.value"
                style="width: 75px; height: 75px"
                rounded
                alt="Rounded image"
              />
            </template>
            <template #cell(name)="data">
              <span>
                <strong> {{ data.item.name }} </strong>
              </span>
            </template>
            <template #cell(charges)="data">
              <span> AED {{ data.item.charges }} </span>
            </template>
            <template #cell(chargesAtHome)="data">
              <span> AED {{ data.item.chargesAtHome }} </span>
            </template>

            <template #cell(status)="data">
              <b-badge
                v-if="data.item.status == 'Approved'"
                variant="success"
                class="badge-glow"
              >
                Approved
              </b-badge>
              <b-badge
                v-if="data.item.status == 'Pending'"
                variant="secondary"
                class="badge-glow"
              >
                Pending
              </b-badge>
              <b-badge
                v-if="data.item.status == 'Suspended'"
                variant="warning"
                class="badge-glow"
              >
                Suspended
              </b-badge>
              <b-badge
                v-if="data.item.status == 'Denied'"
                variant="danger"
                class="badge-glow"
              >
                Denied
              </b-badge>
            </template>

            <template #cell(actions)="data">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                v-b-modal.add-product
                @click="EditProduct(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon"
                @click="DeleteProduct(data.item.id)"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </template>
            <!-- <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template> -->
          </b-table>
          <b-row v-if="filterProducts.length == 0">
            <b-col cols="12" class="demo-spacing-0">
              <b-alert variant="primary" show>
                <div class="alert-body">
                  <span
                    ><strong>Empty!</strong> There is no record to
                    display.</span
                  >
                </div>
              </b-alert>
            </b-col>
          </b-row>
        </b-card>
      </div>

      <div v-if="productEmpty == true">
        <b-alert show variant="secondary" class="p-2">No Data</b-alert>
      </div>
      <b-row class="mt-2" v-if="itemView == 'grid-view'">
        <b-col
          v-for="product in filterProducts"
          v-bind:key="product.id"
          xl="3"
          lg="3"
          cols="12"
          md="4"
          sm="6"
        >
          <!--<b-card-group deck v-for="p in myObj" :key="p.name">-->
          <a v-b-modal.add-product @click="EditProduct(product)">
            <b-card
              :img-src="product.cover"
              img-top
              img-alt="card img"
              img-height="200px"
              border-variant="light"
              v-if="productEmpty == false"
            >
              <b-row style="margin-top: 5px">
                <b-col md="12">
                  <b-card-text>
                    <b> {{ product.name }} </b>
                  </b-card-text>
                </b-col>
              </b-row>

              <b-row style="margin-top: 5px">
                <b-col md="12">
                  <b-card-text>
                    {{ product.category }} -> {{ product.subcategory }}
                  </b-card-text>
                </b-col>
              </b-row>
              <b-row style="margin-top: 5px">
                <b-col md="9">
                  <b-card-text> AED {{ product.charges }} </b-card-text>
                </b-col>
              </b-row>
              <b-row style="margin-top: 5px">
                <b-col md="9">
                  <b-card-text>
                    <b-badge
                      v-if="product.status == 'Approved'"
                      variant="success"
                      class="badge-glow"
                    >
                      Approved
                    </b-badge>

                    <b-badge
                      v-if="product.status == 'Pending'"
                      variant="secondary"
                      class="badge-glow"
                    >
                      Pending
                    </b-badge>
                    <b-badge
                      v-if="product.status == 'Suspended'"
                      variant="warning"
                      class="badge-glow"
                    >
                      Suspended
                    </b-badge>
                    <b-badge
                      v-if="product.status == 'Denied'"
                      variant="danger"
                      class="badge-glow"
                    >
                      Denied
                    </b-badge>
                  </b-card-text>
                </b-col>
              </b-row>
            </b-card>
          </a>

          <!--</b-card-group>-->
        </b-col>
        <b-col
          v-if="filterProducts.length == 0"
          cols="12"
          class="demo-spacing-0"
        >
          <b-alert variant="primary" show>
            <div class="alert-body">
              <span
                ><strong>Empty!</strong> There is no record to display.</span
              >
            </div>
          </b-alert>
        </b-col>
      </b-row>

      <!-- Pagination -->
      <b-col
        cols="12"
        sm="6"
        class="
          d-flex
          align-items-center
          justify-content-center justify-content-sm-end
        "
      >
        <!-- <b-pagination
            v-model="currentPage"
            :total-rows="totalOrders"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination> -->
      </b-col>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BCard,
  BCardBody,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BFormTextarea,
  BMedia,
  BMediaAside,
  BButton,
  BPagination,
  BDropdown,
  BDropdownItem,
  BAlert,
  BSpinner,
  BCardImg,
  BImg,
  BFormRadioGroup,
  BFormRadio,
  BAvatar,
  BTable,
  BLink,
  BBadge,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import axios from '@axios';
import { BFormSelect } from 'bootstrap-vue';
import VueGallery from 'vue-gallery';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
export default {
  props: ['title'],
  components: {
    // flatPickr,
    vSelect,
    BTable,
    //VueGoodTable,
    BBadge,
    //BAvatar,
    BForm,
    BFormTextarea,
    // BFormCheckbox,
    BFormInput,
    BCard,
    BCardText,
    BRow,
    BFormGroup,
    //BMedia,
    BSpinner,
    BImg,
    BLink,
    //BMediaAside,
    //BCardBody,
    BFormRadioGroup,
    BFormRadio,
    BCol,
    BButton,
    // BPagination,
    //BDropdown,
    //BDropdownItem,
    //BCardImg,
    //BFormSelect,
    BAlert,
    BSpinner,
    //gallery: VueGallery,
  },

  created() {
    this.LoadData();
  },
  computed: {
    filterProducts: function () {
      return this.myProducts.filter((pro) => {
        return pro.name.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  mounted() {},
  data() {
    return {
      imgerr: false,
      showVisit: false,
      mycategoryId: 0,
      dataloading: false,
      plus: '',
      moreloading: false,
      logoloading: false,
      mysubCategories: [],
      availability: ['Home', 'Salon', 'Home & Salon'],
      categories: [],
      subCategories: [],
      deleteObj: {
        productIds: [],
        isDeleted: '',
      },
      statusObj: {
        productIds: [],
        status: '',
      },

      selected: [],
      rows: [],
      columns: [
        {
          label: '',
          key: 'cover',
        },
        {
          label: 'Name',
          key: 'name',
        },

        {
          label: 'Charges',
          key: 'charges',
        },
        {
          label: 'Visiting charges',
          key: 'chargesAtHome',
        },
        {
          label: 'Status',
          key: 'status',
        },

        {
          label: '',
          key: 'actions',
        },
      ],
      itemViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
      ],
      itemView: 'list-view',
      searchQuery: '',
      loading: false,
      filterData: '',
      date: {
        range: '',
      },
      detailObj: {},
      index: null,
      rangeDate: null,
      productfilterOptions: [
        { text: 'Most sold product', value: 'mostSold' },
        { text: 'Least sold product', value: 'leastSold' },
        { text: 'Most popular product', value: 'mostPopular' },
        { text: 'Least popular product', value: 'leastPopular' },
        { text: 'Most profitable product', value: 'mostProfitable' },
      ],
      cakeLayer: [],
      myProducts: [],
      productEmpty: false,
      myObj: {
        id: 0,
        name: '',
        images: [],
        forHome: '',
        categoryId: 0,
        charges: 0,
        subcategoryId: 0,
        vendorId: '',
        status: 'Pending',
        chargesAtHome: 0,
        chargesAtSalon: 0,
      },
      cakeImages: [],
      flavour: [],
      cakeSize: [],
    };
  },
  methods: {
    HomeCharges() {
      console.log(this.myObj.forHome);
      if (this.myObj.forHome !== 'Salon') {
        this.showVisit = true;
      } else {
        this.showVisit = false;
      }
    },
    CheckCategory() {
      var elem = this.$refs['category'];
      if (this.myObj.categoryId == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCharges() {
      const regex = /^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/;
      var elem = this.$refs['charges'];
      if (regex.test(this.myObj.charges)) {
        if (this.myObj.charges == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else {
        return (elem.state = false);
      }
    },
    CheckAvailability() {
      var elem = this.$refs['availability'];
      if (this.myObj.forHome == '') {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDescription() {
      var elem = this.$refs['desc'];
      if (this.myObj.description == '') {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckPicture() {
      console.log(this.myObj.images.length);
      if (this.myObj.images.length == 0) {
        return (this.imgerr = true);
      } else {
        return (this.imgerr = false);
      }
    },
    CheckSubCategory() {
      var elem = this.$refs['subcategory'];
      if (this.myObj.subcategoryId == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckName() {
      var elem = this.$refs['name'];
      if (this.myObj.name == '') {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    EmptyForm() {
      if (this.$store.state.userData.service == 'Bronze') {
        if (this.myProducts.length < 5) {
          this.myObj.id = 0;
          this.myObj.name = '';
          this.myObj.images = [];
          this.myObj.charges = 0;
          this.myObj.forHome = '';
          this.myObj.description = '';
          this.myObj.categoryId = 0;
          this.myObj.subcategoryId = 0;
          this.myObj.vendorId = 0;
          this.$bvModal.show('add-product');
        } else {
          Swal.fire(
            'Limit Exceeded!',
            'Please upgrade your package to upload more services.',
            'warning',
          );
        }
      } else {
        this.myObj.id = 0;
        this.myObj.name = '';
        this.myObj.images = [];
        this.myObj.charges = 0;
        this.myObj.forHome = '';
        this.myObj.description = '';
        this.myObj.categoryId = 0;
        this.myObj.subcategoryId = 0;
        this.myObj.vendorId = 0;
        this.$bvModal.show('add-product');
      }
    },
    EditProduct(row) {
      this.myObj.id = row.id;
      this.myObj.name = row.name;
      this.myObj.images = row.images.split(',');
      this.myObj.charges = row.charges;
      this.myObj.forHome = row.forHome;
      this.myObj.description = row.description;
      this.myObj.categoryId = row.categoryId;
      this.myObj.subcategoryId = row.subcategoryId;
      this.myObj.status = row.status;
      this.myObj.vendorId = row.vendorId;
      this.myObj.chargesAtHome = row.chargesAtHome;
      this.myObj.chargesAtSalon = row.chargesAtSalon;
      this.HomeCharges();
      this.mysubCategories = [];
      this.subCategories.forEach((e) => {
        if (e.categoryId == this.myObj.categoryId) {
          this.mysubCategories.push(e);
        }
      });
      console.log(row);
    },
    checkCategory() {
      if (this.myObj.categoryId == 0 || this.myObj.categoryId == null) {
        return (this.errors.category = true);
      } else {
        return (this.errors.category = false);
      }
    },

    AddProduct() {
      this.CheckCategory();
      this.CheckDescription();
      this.CheckSubCategory();
      this.CheckName();
      this.CheckCharges();
      this.CheckAvailability();
      this.CheckPicture();
      if (
        this.CheckCategory() == false ||
        this.CheckDescription() == false ||
        this.CheckSubCategory() == false ||
        this.CheckName() == false ||
        this.CheckCharges() == false ||
        this.CheckAvailability() == false ||
        this.CheckPicture() == true
      ) {
        return 0;
      } else {
        var sendObj = {
          id: this.myObj.id,
          name: this.myObj.name,
          description: this.myObj.description,
          images: this.myObj.images.toString(),
          charges: parseInt(this.myObj.charges),
          categoryId: this.myObj.categoryId,
          subcategoryId: this.myObj.subcategoryId,
          vendorId: this.$store.state.userData.userID,
          status: this.myObj.status,
          chargesAtHome: this.myObj.chargesAtHome,
          chargesAtSalon: this.myObj.chargesAtSalon,
          forHome: this.myObj.forHome,
        };
        // this.myObj.charges = parseInt(this.myObj.charges);
        // this.myObj.images = this.myObj.images.toString();
        // this.myObj.vendorId = this.$store.state.userData.userID;
        //return console.log(this.myObj);

        if (this.myObj.id == 0) {
          console.log(this.myObj);
          var axios = require('axios');

          var config = {
            method: 'post',
            url: 'https://brandaway.fastech.pk/api/Services/AddService',
            headers: {
              Authorization: 'Bearer ' + this.$store.state.userData.token,
              'Content-Type': 'application/json',
            },
            data: sendObj,
          };
          axios(config)
            .then((response) => {
              //console.log(response.data);
              if (response.data.status == 'success') {
                  this.$bvModal.hide('add-product');
                this.LoadData();
                this.$bvToast.toast('Service Added.', {
                  title: 'Success!',
                  variant: 'success',
                  toaster: 'b-toaster-bottom-center',
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else if (this.myObj.id != 0) {
          console.log(this.myObj);
          var axios = require('axios');

          var config = {
            method: 'put',
            url: 'https://brandaway.fastech.pk/api/Services/UpdateService',
            headers: {
              Authorization: 'Bearer ' + this.$store.state.userData.token,
              'Content-Type': 'application/json',
            },
            data: sendObj,
          };
          axios(config)
            .then((response) => {
              //console.log(response.data);
              if (response.data.status == 'success') {
                  this.$bvModal.hide('add-product');
                this.LoadData();
                this.$bvToast.toast('Service Updated.', {
                  title: 'Success!',
                  variant: 'success',
                  toaster: 'b-toaster-bottom-center',
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }
    },

    LoadsubCategory() {
      this.mysubCategories = [];
      this.subCategories.forEach((e) => {
        if (e.categoryId == this.myObj.categoryId) {
          this.mysubCategories.push(e);
        }
      });
      console.log(this.mysubCategories);
    },
    rowClass(item, type) {
      const colorClass = 'table-secondary';
      if (!item || type !== 'row') {
        return;
      }

      // eslint-disable-next-line consistent-return
      //console.log("Hello", item);
      if (item.status == 'inactive') {
        return colorClass;
      }
    },
    DeletedAll(deleted) {
      this.deleteObj.productIds = [];
      this.selected.forEach((elem) => {
        this.deleteObj.productIds.push(elem.productId);
      });
      this.deleteObj.isDeleted = deleted;
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          var axios = require('axios');

          var config = {
            method: 'put',
            url: 'https://okaaik.fastech.pk/api/products/RecentlyDelete',
            headers: {
              Authorization:
                'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI',
            },
            data: this.deleteObj,
          };

          axios(config)
            .then((response) => {
              console.log(response.data);
              if (response.data.status === 'success') {
                Swal.fire(
                  'Deleted!',
                  'Your products has been deleted.',
                  'success',
                ).then((res) => {
                  this.LoadData();
                  this.$bvModal.hide('modal-select2');
                });
              } else if (response.data.status == 'exist') {
                Swal.fire(
                  'Not Allowed!',
                  'You cannot delete </br>' +
                    " ' " +
                    response.data.data +
                    " ' " +
                    '</br> these has orders.',
                  'warning',
                ).then((res) => {
                  this.LoadData();
                  this.$bvModal.hide('modal-select2');
                });
              } else {
                Swal.fire('Error!', 'Something went wrong.', 'error');
              }

              console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    ActiveAll(status) {
      this.statusObj.productIds = [];
      this.selected.forEach((elem) => {
        this.statusObj.productIds.push(elem.productId);
      });
      this.statusObj.status = status;
      ///console.log()
      var axios = require('axios');

      var config = {
        method: 'post',
        url: 'https://okaaik.fastech.pk/api/products/ActiveAll',
        headers: {
          Authorization:
            'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI',
        },
        data: this.statusObj,
      };

      axios(config)
        .then((response) => {
          if (response.data.status == 'success') {
            this.LoadData();
            this.$bvToast.toast('Status updated.', {
              title: 'Success!',
              variant: 'success',
              toaster: 'b-toaster-bottom-center',
            });
          }
          console.log(response.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    onRowSelected(items) {
      this.selected = items;
      console.log(this.selected);
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    // AddProduct()
    // {
    //   this.$store.state.userData.productId = 0;
    //   console.log("storeId", this.$store.state.userData.productId);
    //   this.$router.push('/apps/products/add');

    // },
    ProductEdit(id) {
      this.$store.state.userData.productId = id;

      console.log('storeId', this.$store.state.userData.productId);
      this.$router.push('/apps/products/add');
    },
    Filter() {
      // console.log("Osama");
      console.log(this.date.range, this.filterData.value);
      var split = this.date.range.split(' ');
      var from = split[0];
      var to = split[2];

      //console.log(from );
    },
    ProductDetails(id) {
      this.loading = true;
      this.cakeImages = [];
      this.cakeLayers = [];
      this.cakeSize = [];
      this.flavour = [];
      console.log(id);
      var axios = require('axios');

      var config = {
        method: 'get',
        url: 'https://okaaik.fastech.pk/api/products/GetproductbyId/' + id,
        headers: {
          Authorization:
            'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI',
        },
      };

      axios(config)
        .then((response) => {
          //this.cakeImages.push(response.data.images);
          console.log(response.data);
          this.cakeImages = [];
          response.data.images.forEach((elem) => {
            this.cakeImages.push(elem.imagePath);
          });
          this.cakeSize.push(response.data.cakesize);
          this.flavour.push(response.data.flavours);
          this.detailObj = response.data.data[0];
          this.cakeLayers = response.data.cakelayer;
          console.log('Mylayers', this.cakeLayers);
          console.log(this.detailObj);
          console.log(this.cakeImages);
          console.log(this.cakeSize[0]);

          console.log(this.flavour[0]);
          this.loading = false;
          //console.log( response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    DeleteProduct(id) {
      // this.deleteObj.productIds = [];
      // this.deleteObj.productIds.push(id);
      // this.deleteObj.isDeleted = true;
      /// return console.log(this.deleteObj);
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          var axios = require('axios');

          var config = {
            method: 'delete',
            url: 'https://brandaway.fastech.pk/api/Services/DeleteService/' + id,
            headers: {
              Authorization: 'bearer ' + this.$store.state.userData.token,
            },
          };

          axios(config)
            .then((response) => {
              console.log(response.data);
              if (response.data.status === 'success') {
                Swal.fire(
                  'Deleted!',
                  'Your service has been deleted.',
                  'success',
                ).then((res) => {
                  this.LoadData();
                  this.$bvModal.hide('modal-select2');
                });
              } else if (response.data.status == 'booked') {
                Swal.fire(
                  'Not Allowed!',
                  'You cannot delete this service' + '</br> it has bookings.',
                  'warning',
                ).then((res) => {
                  this.LoadData();
                  this.$bvModal.hide('modal-select2');
                });
              } else {
                Swal.fire('Error!', 'Something went wrong.', 'error');
              }

              console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },

    removeImg(index) {
      console.log(index);
      this.myObj.images.splice(index, 1);
    },

    selectMoreFile() {
      //this.moreFiles = this.$refs.mfile.files;
      if (this.$refs.mfile.files.length !== 0) {
        this.moreloading = true;
        var formdata = new FormData();
        for (var i = 0; i < this.$refs.mfile.files.length; i++) {
          formdata.append('file', this.$refs.mfile.files[i]);
        }

        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow',
        };

        fetch('https://upload.appick.io', requestOptions)
          .then((response) => response.json()) //2
          .then((result) => {
            console.log();
            //this.moreImages.push(result.myresp);

            for (var i = 0, len = result.myresp.length; i < len; ++i) {
              this.moreloading = false;
              var fn = result.myresp[i].path;
              this.myObj.images.push(fn);
            }
            this.CheckPicture();
            console.log(this.myObj.images);
          })

          .catch((error) => console.log('error', error));
      }
    },
    LoadData() {
      var axios = require('axios');

      var screen = {
        method: 'get',
        url:
          'https://brandaway.fastech.pk/api/Services/LoadServiceScreen/' +
          this.$store.state.userData.userID +
          '/' +
          this.$store.state.userData.type,
        headers: {
          Authorization: 'bearer ' + this.$store.state.userData.token,
        },
      };

      axios(screen)
        .then((response) => {
          console.log(response.data);

          //  this.data  =  response.data;
          console.log(response.data);
          this.myProducts = response.data.services;
          this.categories = response.data.categories;
          this.subCategories = response.data.subCategories;

          //  this.rows = response.data.data;

          console.log('HelloService', this.myProducts);
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
</style>
<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  margin: 5px;
}
</style>

